import { cartSchema } from '@util/types/firestore/carts';
import { z } from 'zod';

export const getCartArgsSchema = z.object({
  uid: z.string(),
});

export const setCartArgsSchema = z.object({
  newCartState: cartSchema,
});
export type GetCartArgs = z.infer<typeof getCartArgsSchema>;
export type SetCartArgs = z.infer<typeof setCartArgsSchema>;
