import { db } from '@util/firebase';
import { nonNullable } from '@util/index';
import {
  BlogCommentDocument,
  blogCommentDocumentSchema,
  BlogDocument,
} from '@util/types/firestore/blog';
import {
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  endBefore,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  query,
  QueryConstraint,
  setDoc,
  startAfter,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import {
  deleteObject,
  getStorage,
  ref,
  uploadBytes,
  uploadString,
} from 'firebase/storage';
import { ApiResponse } from 'models/shared';
import { DeleteCommentArgs, GetBlogCommentsArgs } from './blog.types';

const storage = getStorage();

const blogCommentsRef = collection(
  db,
  'blog_comments'
) as CollectionReference<BlogCommentDocument>;
const blogsRef = collection(db, 'blogs') as CollectionReference<BlogDocument>;

export async function getBlogSlugs(): Promise<ApiResponse<string[]>> {
  const q = query(blogsRef, limit(7));
  const snapshot = await getDocs(q);
  const results = snapshot.docs.map((doc) => doc.data().slug);
  return {
    results,
  };
}

const DEFAULT_LIMIT = 7;
type GetBlogsArgs = {
  tag?: string;
  limit?: number;
  pageInfo?: { cursor: string | null; direction: 'next' | 'prev' | null };
};

export type CursorPagination<T> = {
  prev: T | null;
  next: T | null;
  currentCursor: T | null;
  direction: 'next' | 'prev' | null;
  totalResults: number;
};

type GetBlogsResponse = {
  results: BlogDocument[];
  pageInfo: CursorPagination<string>;
};

export async function getBlogs(args: GetBlogsArgs): Promise<GetBlogsResponse> {
  const resultsLimit = args.limit ?? DEFAULT_LIMIT;
  let cursorConstraint: QueryConstraint | undefined;
  let limitOrLimitToLast = limit;
  if (args.pageInfo && args.pageInfo.cursor && args.pageInfo.direction) {
    const { cursor, direction } = args.pageInfo;
    const startAfterOrEndBefore = direction === 'next' ? startAfter : endBefore;
    const cursorSnapshot = await getDoc(doc(blogsRef, cursor));
    cursorConstraint = startAfterOrEndBefore(cursorSnapshot);
    limitOrLimitToLast = direction === 'prev' ? limitToLast : limit;
  }

  const contraintsUnlimited = [
    args.tag ? where('tags', 'array-contains', args.tag) : null,
    orderBy('created', 'desc'),
  ].filter(nonNullable);

  const constraintsWithCursor = [
    ...contraintsUnlimited,
    cursorConstraint,
  ].filter(nonNullable);

  const qLimited = query(
    blogsRef,
    ...constraintsWithCursor,
    limitOrLimitToLast(resultsLimit)
  );
  const qUnlimited = query(blogsRef, ...contraintsUnlimited);

  const limitedPromise = getDocs(qLimited);
  const unlimitedPromise = getCountFromServer(qUnlimited);

  const [limitedSnapshot, totalResultsSnapshot] = await Promise.all([
    limitedPromise,
    unlimitedPromise,
  ]);

  const results = limitedSnapshot.docs.map((doc) => doc.data());
  const prevId = results[0]?.id;
  const nextId = results[results.length - 1]?.id;
  const [prev, next] = await Promise.all([
    getValidCursorOrNull(prevId, 'prev', contraintsUnlimited),
    getValidCursorOrNull(nextId, 'next', contraintsUnlimited),
  ]);
  const totalResults = totalResultsSnapshot.data().count;

  return {
    results,
    pageInfo: {
      totalResults,
      currentCursor: args.pageInfo?.cursor ?? null,
      direction: args.pageInfo?.direction ?? null,
      next,
      prev,
    },
  };
}

// returns the cursor if it has results, otherwise returns null
async function getValidCursorOrNull(
  cursor: string,
  direction: 'next' | 'prev',
  constraints: QueryConstraint[] = []
) {
  if (!cursor) return null;
  const cursorSnapshot = await getDoc(doc(blogsRef, cursor));
  const startAfterOrEndBefore = direction === 'next' ? startAfter : endBefore;
  const q = query(
    blogsRef,
    ...constraints,
    startAfterOrEndBefore(cursorSnapshot)
  );
  const snapshot = await getDocs(q);
  const document = snapshot.docs[0];
  if (!document) {
    return null;
  }
  return cursor;
}

export async function getNBlogs(
  n: number
): Promise<ApiResponse<BlogDocument[]>> {
  const q = query(blogsRef, orderBy('created', 'desc'), limit(n));
  const snapshot = await getDocs(q);
  const results = snapshot.docs.map((doc) => doc.data());
  return {
    results,
  };
}

export async function getBlogPagination(timestamp: number) {
  const prevQuery = query(
    blogsRef,
    where('created', '<', timestamp),
    orderBy('created', 'desc'),
    limit(1)
  );
  const prevSnap = await getDocs(prevQuery);
  const prev = prevSnap.docs[0]?.data();
  let last;
  if (!prev) {
    // if there is no prev, get last blog
    const lastQuery = query(blogsRef, orderBy('created', 'desc'), limit(1));
    const lastSnap = await getDocs(lastQuery);
    last = lastSnap.docs[0]?.data();
  }

  const nextQuery = query(
    blogsRef,
    where('created', '>', timestamp),
    orderBy('created', 'asc'),
    limit(1)
  );
  const nextSnap = await getDocs(nextQuery);
  const next = nextSnap.docs[0]?.data();

  let first;
  if (!next) {
    // if there is no next, get first blog
    const firstQuery = query(blogsRef, orderBy('created', 'asc'), limit(1));
    const firstSnap = await getDocs(firstQuery);
    first = firstSnap.docs[0]?.data();
  }
  return {
    prev: prev ?? last,
    next: next ?? first,
  };
}

export async function getBlogById(id: string): Promise<BlogDocument | null> {
  const docRef = doc(blogsRef, id);
  const docSnap = await getDoc(docRef);
  const data = docSnap.data();
  return data ?? null;
}

export async function getBlogBySlug(
  slug: string
): Promise<ApiResponse<BlogDocument | null>> {
  const q = query(blogsRef, where('slug', '==', slug), limit(1));
  const snapshot = await getDocs(q);
  const data = snapshot.docs[0]?.data();
  return {
    results: data ?? null,
  };
}

export async function deleteBlog(id: string) {
  const docRef = doc(blogsRef, id);
  return deleteDoc(docRef);
}

export async function deleteBlogImage({
  thumb,
  full,
}: {
  thumb: string;
  full: string;
}) {
  if (!thumb || !full) return;
  // delete thumb and full image
  const thumbRef = ref(storage, thumb);
  const fullRef = ref(storage, full);
  const t = deleteObject(thumbRef);
  const f = deleteObject(fullRef);
  await Promise.all([t, f]);
}

export function getBlogCommenttId(): string {
  const newDocRef = doc(blogCommentsRef);
  const id = newDocRef.id;
  return id;
}

export async function postComment(commentDoc: BlogCommentDocument) {
  const comment_id = getBlogCommenttId();
  const parsedData = blogCommentDocumentSchema.parse({
    ...commentDoc,
    comment_id,
  });
  const docRef = doc(blogCommentsRef, parsedData.comment_id);
  return setDoc(docRef, parsedData);
}

export async function deleteComment(args: DeleteCommentArgs) {
  const docRef = doc(blogCommentsRef, args.commentId);
  deleteDoc(docRef);
}

export async function getBlogComments(
  args: GetBlogCommentsArgs
): Promise<ApiResponse<BlogCommentDocument[]>> {
  const q = query(
    blogCommentsRef,
    where('blog_id', '==', args.blogId),
    where('parent_id', '==', args.parentId),
    orderBy('timestamp', 'desc')
    // infinitei scroll limit?
  );
  const snap = await getDocs(q);
  const results = snap.docs.map((doc) => doc.data());
  return {
    results,
  };
}

export async function uploadBlogImage({
  data,
  blogId,
}: {
  data: string | Blob;
  blogId: string;
}) {
  if (!blogId) invalidArgsError();
  const path = `blog/${blogId}/${Date.now()}`;
  const blogImagesRef = ref(storage, path);
  if (typeof data === 'string') {
    if (!data.startsWith('data')) data = 'data:image/jpeg;base64,' + data;
    const res = await uploadString(blogImagesRef, data, 'data_url');
    return res.metadata.fullPath;
  } else {
    const res = await uploadBytes(blogImagesRef, data);
    return res.metadata.fullPath;
  }
}

export async function createBlog(blog: BlogDocument) {
  // if blog is featured, make sure there are no other featured blogs
  if (blog.is_featured) {
    const q = query(blogsRef, where('is_featured', '==', true));
    const snap = await getDocs(q);
    if (snap.docs.length > 0) {
      // update the other featured blog to not be featured
      const batch = writeBatch(db);
      snap.docs.forEach((featBlog) => {
        const docRef = doc(blogsRef, featBlog.id);
        batch.update(docRef, { is_featured: false });
      });
      await batch.commit();
    }
  }
  const docRef = doc(blogsRef, blog.id);
  return setDoc(docRef, blog);
}

export async function getFeaturedBlog(): Promise<BlogDocument | null> {
  const q = query(blogsRef, where('is_featured', '==', true));
  const snap = await getDocs(q);
  const data = snap.docs[0]?.data();
  return data ?? null;
}

export async function updateBlog(blog: BlogDocument) {
  const docRef = doc(blogsRef, blog.id);
  await updateDoc(docRef, blog);
}

export async function getBlogId(): Promise<string> {
  const newDocRef = doc(blogsRef);
  const id = newDocRef.id;
  return id;
}

export function invalidArgsError(): never {
  throw new Error('Invalid arguments');
}
