import { z } from 'zod';

export const deleteCommentArgsSchema = z.object({
  commentId: z.string(),
});

export const getBlogCommentsArgsSchema = z.object({
  blogId: z.string(),
  parentId: z.string().nullable(),
});

// export type PostCommentReplyArgs = z.infer<typeof postCommentReplyArgsSchema>;
export type DeleteCommentArgs = z.infer<typeof deleteCommentArgsSchema>;
export type GetBlogCommentsArgs = z.infer<typeof getBlogCommentsArgsSchema>;
