import { z } from 'zod';

export const brandSchema = z.object({
  bike_parts: z.boolean().optional(),
  description: z.string().default(''),
  image: z.string().optional(),
  letter: z.string(),
  mountain_bike: z.boolean().optional(),
  name: z.string().trim().min(1),
  riding_gear: z.boolean().optional(),
  url: z.string(),
});

export const ALL_BRANDS: readonly string[] = [
  '6D',
  '100%',
  '365MX',
  'Acerbis',
  'Aektiv',
  'Airoh',
  'Alias',
  'All Balls',
  'Allsports Dynamics',
  'Alpinestars',
  'Answer',
  'Antigravity',
  'Arai',
  'Artrax',
  'Asterisk',
  'ASV',
  'Athena',
  'Bel-Ray',
  'Bell',
  'Big Gun',
  'BikeMaster',
  "Bill's Pipes",
  'Bolt',
  'Boyesen',
  'Bridgestone',
  'BUD Racing',
  'Cycra',
  'DEI',
  'DID',
  'Driven',
  'Dunlop',
  "D'COR Visuals",
  'EBC',
  'EKS',
  'Engine Ice',
  'Enzo',
  'EVS',
  'Excel',
  'Factory Connection',
  'Factory Effex',
  'Fasthouse',
  'Fastway',
  'Flo Motorsports',
  'FLU Designs',
  'Fly Racing',
  'FMF',
  'Forma',
  'Fox Racing',
  'FXR',
  'Gaerne',
  'Galfer',
  'Garmin',
  'Goldentyre',
  'GYTR',
  'Hammerhead',
  'HGS',
  'Hinson',
  'Hoosier',
  'Hotcams',
  'HRC',
  'Husqvarna',
  'IMS',
  'JD Jetting',
  'Just 1',
  'K&N',
  'Kenda',
  'KTM',
  'KTM PowerParts',
  'KYB',
  'Leatt',
  'Lucas Oil',
  'Maxima',
  'Maxxis',
  'Michelin',
  'Mika Metals',
  'Mobius',
  'Monster',
  'Motion Pro',
  'Moto Stuff',
  'Motorex',
  'MotoSeat',
  'Motul',
  'MSR',
  'MX Locker',
  'N-Style',
  'No Toil',
  'Oakley',
  'ODI',
  'Ogio',
  'Ohlins',
  "O'Neal",
  'Pirelli',
  'Pivot Works',
  'Pod MX',
  'Polisport',
  'Pro Circuit',
  'Pro Honda',
  'Pro Taper',
  'Pro Wheel',
  'Race Tech',
  'Red Bull',
  'Red Line',
  'Rekluse',
  'Renthal',
  'Ride Engineering',
  'Rims Plus Wheels',
  'Scott',
  'Seven',
  'Shift',
  'Shoei',
  'Showa',
  'Sidi',
  'Silkolene',
  'Spectro',
  'Spy',
  'Sunstar',
  'TAG',
  'Talon',
  'TCX',
  'Tecmate',
  'Thor',
  'Throttle Syndicate',
  'Torc1 Racing',
  'Troy Lee Designs',
  'Turner Performance Products',
  'Tusk',
  'Twin Air',
  'UNI',
  'VForce',
  'Von Zipper',
  'Vortex',
  'Wiseco',
  'Works Connection',
  'WP',
  'Yoshimura',
] as const;

export const GEAR_BRANDS: readonly string[] = [
  '100%',
  '365MX',
  '6D',
  'Acerbis',
  'Aektiv',
  'Airoh',
  'Alias',
  'Allsports Dynamics',
  'Alpinestars',
  'Answer',
  'Arai',
  'Asterisk',
  'Bell',
  'EKS',
  'EVS',
  'Fasthouse',
  'Fly Racing',
  'FMF',
  'Forma',
  'Fox Racing',
  'FXR',
  'Gaerne',
  'Just 1',
  'KTM',
  'Leatt',
  'Mobius',
  'Monster',
  'MSR',
  'MX Locker',
  "O'Neal",
  'Oakley',
  'Ogio',
  'Pod MX',
  'Red Bull',
  'Scott',
  'Seven MX',
  'Shift',
  'Shoei',
  'Sidi',
  'Spy',
  'TCX',
  'Thor',
  'Troy Lee Designs',
  'Von Zipper',
] as const;

export const PART_BRANDS: readonly string[] = [
  'OEM',
  'Acerbis',
  'All Balls',
  'Antigravity',
  'Artrax',
  'ASV',
  'Athena',
  'Bel - Ray',
  'Big Gun',
  'BikeMaster',
  "Bill's Pipes",
  'Bolt',
  'Boyesen',
  'Bridgestone',
  'BUD Racing',
  'Cycra',
  "D'COR Visuals",
  'DEI',
  'DID',
  'Driven',
  'Dunlop',
  'EBC',
  'Engine Ice',
  'Enzo',
  'Excel',
  'Factory Connection',
  'Factory Effex',
  'Fastway',
  'Flo Motorsports',
  'FLU Designs',
  'FMF',
  'Galfer',
  'Garmin',
  'Goldentyre',
  'GYTR',
  'Hammerhead',
  'HGS',
  'Hinson',
  'Hoosier',
  'Hotcams',
  'HRC',
  'Husqvarna',
  'IMS',
  'JD Jetting',
  'K & N',
  'Kenda',
  'KTM PowerParts',
  'KYB',
  'Lucas Oil',
  'Maxima',
  'Maxxis',
  'Michelin',
  'Mika Metals',
  'Motion Pro',
  'Motorex',
  'MotoSeat',
  'Moto Stuff',
  'Motul',
  'N - Style',
  'No Toil',
  'ODI',
  'Ohlins',
  'Pirelli',
  'Pivot Works',
  'Polisport',
  'Pro Circuit',
  'Pro Honda',
  'Pro Taper',
  'Pro Wheel',
  'Race Tech',
  'Red Line',
  'Rekluse',
  'Renthal',
  'Ride Engineering',
  'Rims Plus Wheels',
  'Scott',
  'Showa',
  'Silkolene',
  'Spectro',
  'Sunstar',
  'Talon',
  'TAG',
  'Tecmate',
  'Throttle Syndicate',
  'TM Designworks',
  'Torc1 Racing',
  'Turner Performance Products',
  'Tusk',
  'Twin Air',
  'UNI',
  'VForce',
  'Vortex',
  'Wiseco',
  'Works Connection',
  'WP',
  'Yoshimura',
] as const;

export type BrandDocument = z.infer<typeof brandSchema>;
