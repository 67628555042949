'use client';
import Link from 'next/link';
import { cva, VariantProps } from 'class-variance-authority';
import Spinner from './Spinner';
import { UrlObject } from 'url';

export const ButtonStyles = cva(
  'select-none cursor-pointer gap-x-2 flex items-center  whitespace-nowrap  transition-colors text-[1.5rem] rounded-[1.5rem]',
  {
    variants: {
      type: {
        primary:
          'font-semibold text-brand-white bg-brand-primary hover:bg-brand-primary-800',
        secondary:
          'font-semibold  bg-brand-secondary text-brand-white hover:bg-brand-secondary-darker',
        sso: 'gap-[1rem] bg-[#F1F1F1]  font-semibold text-input hover:bg-brand-lightest-gray',
        tertiary:
          'font-semibold bg-brand-white border-2 border-brand-black text-brand-black hover:bg-brand-primary-lighter',
        decline:
          'font-semibold bg-brand-white border-2 border-brand-red text-brand-red',
        success: 'font-semibold bg-brand-green text-brand-white',
        error:
          'font-semibold bg-brand-red border-2 border-brand-red text-brand-white hover:bg-red-600',

        text: 'font-inherit text-inherit hover:text-brand-primary focus:outline-none',
        dark: 'font-semibold bg-brand-black text-brand-white hover:bg-brand-lightest-black border-white border-[1px]',
        gray: 'font-semibold bg-brand-lightest-gray text-brand-primary hover:bg-brand-lightest-gray',
        darkgray: 'font-semibold bg-gray-500 text-gray-200 ',
        blue: 'font-semibold bg-brand-affirm text-brand-white hover:bg-blue-600',
        green:
          'border border-solid border-brand-mid-green text-[1.4rem] text-brand-mid-green',
        greenBg: 'font-semibold text-brand-white bg-brand-mid-green rounded-xl',
        affirm:
          'font-semibold text-brand-white bg-brand-affirm rounded-xl mx-auto',
        white: 'font-semibold text-brand-black bg-brand-white rounded-xl',
        outline:
          'font-semibold text-black bg-transparent rounded-xl border border-solid border-white text-white',
        underline:
          'font-inherit text-inherit hover:text-brand-primary focus:outline-none underline',
      },
      height: {
        large: 'h-[5.6rem]',
        normal: 'h-[5rem]',
        small: 'h-[4.4rem]',
        smaller: 'h-[3.6rem]',
        extraSmall: 'h-min-content py-[0.4rem]',
      },
      width: {
        normal: 'px-[4.4rem]',
        mid: 'px-[2.2rem]',
        small: 'px-[1.6rem]',
        smaller: 'px-[1rem]',
        extraSmall: 'px-[0.4rem]',
        fluid: 'w-full p-[1.6rem]',
        min: 'w-fit px-0',
        fixed: 'w-[15rem]',
      },
      align: {
        left: 'justify-start pl-[1.6rem]',
        center: 'justify-center',
        right: 'justify-end pr-[1.6rem]',
      },
      loading: {
        true: 'bg-brand-lightest-gray hover:bg-brand-lightest-gray',
      },
      disabled: {
        true: '!hover:shadow-none !border-none text-brand-gray hover:text-brand-gray !cursor-not-allowed !bg-brand-lighter-gray !hover:bg-brand-lighter-gray',
      },
      noXPadding: {
        true: 'px-0',
      },
      radius: {
        lg: 'rounded-lg',
        xl: 'rounded-xl',
      },
    },
    defaultVariants: {
      type: 'primary',
      height: 'large',
      width: 'normal',
      align: 'center',
    },
  }
);

export interface ButtonProps extends VariantProps<typeof ButtonStyles> {
  type?:
    | 'primary'
    | 'secondary'
    | 'text'
    | 'tertiary'
    | 'sso'
    | 'dark'
    | 'decline'
    | 'error'
    | 'success'
    | 'gray'
    | 'darkgray'
    | 'affirm'
    | 'blue'
    | 'green'
    | 'greenBg'
    | 'white'
    | 'outline'
    | 'underline';
  buttonType?: 'button' | 'submit' | 'reset';
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  text?: string;
  children?: React.ReactNode;
  href?: string | UrlObject;
  target?: string;
  height?: 'normal' | 'small' | 'smaller' | 'extraSmall';
  onClick?: (e?: Event) => void;
  align?: 'left' | 'center' | 'right';
  tabIndex?: string;
  disabled?: boolean;
  noXPadding?: boolean;
  loading?: boolean;
  ariaText?: string;
  className?: string;
}
const Button = ({
  href,
  target,
  text,
  children,
  leadingIcon,
  trailingIcon,
  type = 'primary',
  buttonType = 'button',
  height = 'normal',
  width = 'normal',
  onClick,
  align,
  loading,
  disabled,
  noXPadding,
  ariaText,
  radius,
  className,
}: ButtonProps) => {
  const handleClick = (e: any) => {
    onClick?.(e);
  };

  const InnerContents = () =>
    loading ? (
      <div className="h-8 w-8">
        <Spinner />
      </div>
    ) : (
      <>
        {leadingIcon}
        {children || text}
        {trailingIcon}
      </>
    );

  return href ? (
    <Link
      className={ButtonStyles({
        type,
        height,
        width,
        align,
        loading,
        noXPadding,
        disabled,
        className,
      })}
      href={href}
      target={target}
      onClick={handleClick}
      scroll={true}
      aria-label={text ?? ariaText}
    >
      <InnerContents />
    </Link>
  ) : (
    <button
      type={buttonType}
      className={ButtonStyles({
        type,
        height,
        width,
        align,
        noXPadding,
        disabled,
        radius,
        className,
      })}
      onClick={(e: any) => {
        handleClick(e);
      }}
      disabled={disabled || loading}
    >
      <InnerContents />
    </button>
  );
};

export default Button;
