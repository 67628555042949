import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import Typesense from 'typesense';

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: process.env.NEXT_PUBLIC_TYPESENSE_SEARCH_KEY!, // Be sure to use the search-only-api-key
    nodes: [
      {
        host: process.env.NEXT_PUBLIC_IS_STAGING
          ? 'typesense.mxlocker.dev'
          : 'typesense-lb.mxlocker.com',
        port: 443,
        protocol: 'https',
      },
    ],
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  additionalSearchParameters: {
    query_by: 'title,sku',
  },
});
export const searchClient = typesenseInstantsearchAdapter.searchClient;

export const client = new Typesense.Client({
  apiKey: process.env.NEXT_PUBLIC_TYPESENSE_SEARCH_KEY ?? '',
  nodes: [
    {
      host: process.env.NEXT_PUBLIC_IS_STAGING
        ? 'typesense.mxlocker.dev'
        : 'typesense-lb.mxlocker.com',
      port: 443,
      protocol: 'https',
    },
  ],
  connectionTimeoutSeconds: 10000,
});
