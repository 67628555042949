import { Pagination } from '@util/types/paginator';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { CountryCode } from 'libphonenumber-js/types';
import { z } from 'zod';
import { FacetCounts } from './product';

export const uidSchema = z.string().min(20).max(128);

export const dateTimeSchema = z
  .number()
  .int()
  .gte(1000000000000)
  .lte(3000000000000);

export const countrySchema = z
  .string()
  .transform((x) => x.toUpperCase() as CountryCode)
  .default('US');

// [
//   'ALTA', 'APRILIA', 'ARCTIC CAT', 'ATK', 'BENELLI', 'BETA', 'BMW', 'BOMBARDIER',
//   'BUELL', 'CAN-AM', 'CANNONDALE', 'COBRA', 'DUCATI', 'E-TON', 'EBR', 'GASGAS', 'GOLF CART',
//   'GPX', 'HARLEY-DAVIDSON', 'HONDA', 'HUSABERG', 'HUSQVARNA', 'HYOSUNG', 'INDIAN',
//   'JOHN DEERE', 'KASEA', 'KAWASAKI', 'KOVE', 'KTM', 'KUBOTA', 'KYMCO', 'LEM', 'MOTO GUZZI',
//   'MV AGUSTA', 'POLARIS', 'ROYAL ENFIELD', 'SEGWAY', 'SHERCO', 'Stark Future', 'SUR-RON',
//   'SUZUKI', 'TALARIA', 'TEXTRON', 'TRACKER', 'TRIUMPH', 'VICTORY', 'YAMAHA', 'ZERO'
// ]
const makeLiterals = z.union([
  z.literal('Alta'),
  z.literal('Aprilia'),
  z.literal('Arctic Cat'),
  z.literal('ATK'),
  z.literal('Benelli'),
  z.literal('Beta'),
  z.literal('BMW'),
  z.literal('Bombardier'),
  z.literal('Buell'),
  z.literal('Can-Am'),
  z.literal('Cannondale'),
  z.literal('Cobra'),
  z.literal('Ducati'),
  z.literal('E-Ton'),
  z.literal('EBR'),
  z.literal('Gas Gas'),
  z.literal('GPX'),
  z.literal('Harley Davidson'),
  z.literal('Honda'),
  z.literal('Husaberg'),
  z.literal('Husqvarna'),
  z.literal('Hyosung'),
  z.literal('Indian'),
  z.literal('John Deere'),
  z.literal('Kasea'),
  z.literal('Kawasaki'),
  z.literal('Kove'),
  z.literal('KTM'),
  z.literal('Kubota'),
  z.literal('Kymco'),
  z.literal('Lem'),
  z.literal('Moto Guzzi'),
  z.literal('MV Agusta'),
  z.literal('Polaris'),
  z.literal('Royal Enfield'),
  z.literal('Segway'),
  z.literal('Sherco'),
  z.literal('Stark'),
  z.literal('Surron'),
  z.literal('Suzuki'),
  z.literal('Talaria'),
  z.literal('Textron'),
  z.literal('Tracker'),
  z.literal('Triumph'),
  z.literal('Victory'),
  z.literal('Yamaha'),
  z.literal('Zero'),
]);

export const MAKE_ARRAY = makeLiterals.options.map((make) => make.value).sort();

export type Make = z.infer<typeof makeLiterals>;

export const makeSchema = makeLiterals;
export const rideTypes = z.union([
  z.literal('Dirt Bikes'),
  z.literal('Snow'),
  z.literal('ATV'),
  z.literal('UTV'),
  z.literal('Cycling'),
  z.literal('Mountain Bikes'), // includes "Cycling"
  z.literal('E-Bikes'), // includes "Cycling"
  z.literal('Road Bikes'), // includes "Cycling"
  z.literal('Street'),
  z.literal('Adventure'), // includes "Street" // GROUPED UNDER ADVENTURE
  z.literal('Dual-Sport'), // includes "Street" // GROUPED UNDER ADVENTURE
  z.literal('Touring'), // includes "Street" // GROUPED UNDER ADVENTURE
  z.literal('Cruiser'), // includes "Street"
  z.literal('Sport'), // includes "Street"
]);

export type RideType = z.infer<typeof rideTypes>;

export const rideTypeChoices = [
  'Dirt Bikes',
  'ATV',
  'UTV',
  'Street',
  'Snow',
  'Cycling',
] as const;

export const bikeSchema = z.object({
  make: makeSchema,
  model: z.string().nullable(),
  type: rideTypes.default('Dirt Bikes'),
  year: z.number().nullable(),
});
export interface Bike extends z.output<typeof bikeSchema> {}

export const userBikeSchema = bikeSchema.extend({
  date_added: dateTimeSchema.nullable().default(null),
  downloadUrl: z.string().default(''),
  imageUrl: z.string().default(''),
  in_feed: z.boolean().default(false),
});
export interface UserBike extends z.output<typeof userBikeSchema> {}

export const sizeSchema = z.object({
  letter: z.string().optional(),
  letter1: z.string().optional(),
  number: z.number().optional(),
});

export const variationSelectionSchema = z.preprocess(
  (x: any) => {
    if (x?.letter && !isNaN(x.letter)) {
      x.number = x.letter;
      delete x.letter;
    }
    if (x?.number && isNaN(x.number)) {
      x.letter = x.number;
      delete x.number;
    }
    return x;
  },
  z.object({
    letter: z.string().optional(),
    number: z.number().optional(),
    color: z.string().optional(),
  })
);

export const genderSchema = z.union([
  z.literal('M'),
  z.literal('W'),
  z.literal('Y'),
  z.literal('U'),
]);
export type Gender = z.infer<typeof genderSchema>;

export const notificationTypeSchema = z.union([
  z.literal('staff'),
  z.literal('pow'),
  z.literal('bow'),
  z.literal('arrivals'),
  z.literal('inbox'),
  z.literal('offer'),
  z.literal('order'),
  z.literal('followed'),
  z.literal('offer_seller'),
  z.literal('order_seller'),
  z.literal('marketing'),
  z.literal('all'),
]);

export type NotificationTypes = z.infer<typeof notificationTypeSchema>;

export type ApiResponse<T> = {
  results: T;
};
export type ApiResponsePaginated<T> = {
  results: T[];
  brands?: string[];
  pageInfo: Pagination;
  facet_counts?: FacetCounts;
  shouldUseRecommend?: boolean;
};
export type ApiResponsePaginatedByRef<T> = {
  results: T[];
  brands?: string[];
  lastDocSnapshot?: QueryDocumentSnapshot<T>;
};

export const LETTER_SIZES = [
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  'XXXL',
  'One Size',
];

export const PANT_SIZES: Record<Gender, number[]> = {
  M: [26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48],
  W: [24, 25, 26, 27, 28, 29, 30, 31, 32, 33],
  Y: [12, 14, 16, 18, 20, 22, 24, 26, 28, 30],
  U: [12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44],
};

export const BOOT_SIZES: Record<Gender, string[]> = {
  M: [
    '5',
    '5.5',
    '6',
    '6.5',
    '7',
    '7.5',
    '8',
    '8.5',
    '9',
    '9.5',
    '10',
    '10.5',
    '11',
    '11.5',
    '12',
    '12.5',
    '13',
    '13.5',
    '14',
    '15',
  ],
  W: [
    '4',
    '4.5',
    '5',
    '5.5',
    '6',
    '6.5',
    '7',
    '7.5',
    '8',
    '8.5',
    '9',
    '9.5',
    '10',
    '10.5',
    '11',
    '11.5',
  ],
  Y: [
    '1',
    '1.5',
    '2',
    '2.5',
    '3',
    '3.5',
    '4',
    '4.5',
    '5',
    '5.5',
    '6',
    '6.5',
    '7',
    '7.5',
    '8',
    '8.5',
    '9',
    '9.5',
    '10',
    '10.5',
    '11',
    '11.5',
    '12',
    '12.5',
    '13',
    '13.5',
  ],
  U: [
    '5',
    '5.5',
    '6',
    '6.5',
    '7',
    '7.5',
    '8',
    '8.5',
    '9',
    '9.5',
    '10',
    '10.5',
    '11',
    '11.5',
    '12',
    '12.5',
    '13',
    '13.5',
    '14',
    '15',
  ],
};

export interface Country {
  name: string;
  code: CountryCode;
  currency: string;
  main?: boolean;
}

export const COUNTRIES: Country[] = [
  { code: 'US', currency: 'usd', name: 'United States', main: true },
  { code: 'CA', currency: 'cad', name: 'Canada', main: true },
  { code: 'AU', currency: 'aud', name: 'Australia', main: true },
  { code: 'GB', currency: 'gbp', name: 'United Kingdom', main: true },
  { code: 'NL', currency: 'eur', name: 'Netherlands', main: true },
  { code: 'NZ', currency: 'nzd', name: 'New Zealand', main: true },
  { code: 'AF', currency: 'afn', name: 'Afghanistan' },
  { code: 'AL', currency: 'all', name: 'Albania' },
  { code: 'DZ', currency: 'dzd', name: 'Algeria' },
  { code: 'AO', currency: 'aoa', name: 'Angola' },
  { code: 'VU', currency: 'vuv', name: 'Anuatu' },
  { code: 'AR', currency: 'ars', name: 'Argentina' },
  { code: 'AM', currency: 'amd', name: 'Armenia' },
  { code: 'AW', currency: 'awg', name: 'Aruba' },
  { code: 'AC', currency: 'shp', name: 'Ascension Island' },
  { code: 'AT', currency: 'eur', name: 'Austria' },
  { code: 'AZ', currency: 'azn', name: 'Azerbaijan' },
  { code: 'BS', currency: 'bsd', name: 'Bahamas' },
  { code: 'BD', currency: 'bdt', name: 'Bangladesh' },
  { code: 'BB', currency: 'bbd', name: 'Barbados' },
  { code: 'BE', currency: 'eur', name: 'Belgium' },
  { code: 'BZ', currency: 'bzd', name: 'Belize' },
  { code: 'BM', currency: 'bmd', name: 'Bermuda' },
  { code: 'BO', currency: 'bob', name: 'Bolivia' },
  { code: 'BA', currency: 'bam', name: 'Bosnia & Herzegovina' },
  { code: 'BW', currency: 'bwp', name: 'Botswana' },
  { code: 'BR', currency: 'brl', name: 'Brazil' },
  { code: 'BN', currency: 'bnd', name: 'Brunei Darussalam' },
  { code: 'BG', currency: 'bgn', name: 'Bulgaria' },
  { code: 'BI', currency: 'bif', name: 'Burundi' },
  { code: 'CV', currency: 'cve', name: 'Cabo Verde' },
  { code: 'KH', currency: 'khr', name: 'Cambodia' },
  { code: 'KY', currency: 'kyd', name: 'Cayman Islands' },
  { code: 'CL', currency: 'clp', name: 'Chile' },
  { code: 'CN', currency: 'cny', name: 'China' },
  { code: 'CO', currency: 'cop', name: 'Colombia' },
  { code: 'KM', currency: 'kmf', name: 'Comoros' },
  { code: 'CR', currency: 'crc', name: 'Costa Rica' },
  { code: 'HR', currency: 'hrk', name: 'Croatia' },
  { code: 'CW', currency: 'ang', name: 'Curacao' },
  { code: 'CY', currency: 'eur', name: 'Cyprus' },
  { code: 'CZ', currency: 'czk', name: 'Czech Republic' },
  { code: 'CD', currency: 'cdf', name: 'Democratic Republic Of Congo' },
  { code: 'DK', currency: 'dkk', name: 'Denmark' },
  { code: 'DJ', currency: 'djf', name: 'Djibouti' },
  { code: 'DO', currency: 'dop', name: 'Dominican Republic' },
  { code: 'EG', currency: 'egp', name: 'Egypt' },
  { code: 'EE', currency: 'eur', name: 'Estonia' },
  { code: 'ET', currency: 'etb', name: 'Ethiopia' },
  // { code: "EU", currency: "eur", name: "European Union" },
  { code: 'FK', currency: 'fkp', name: 'Falkland Islands' },
  { code: 'FJ', currency: 'fjd', name: 'Fiji' },
  { code: 'FI', currency: 'eur', name: 'Finland' },
  { code: 'FR', currency: 'eur', name: 'France' },
  { code: 'GM', currency: 'gmd', name: 'Gambia' },
  { code: 'GE', currency: 'gel', name: 'Georgia' },
  { code: 'DE', currency: 'eur', name: 'Germany' },
  { code: 'GI', currency: 'gip', name: 'Gibraltar' },
  { code: 'GR', currency: 'eur', name: 'Greece' },
  { code: 'GT', currency: 'gtq', name: 'Guatemala' },
  { code: 'GN', currency: 'gnf', name: 'Guinea' },
  { code: 'GY', currency: 'gyd', name: 'Guyana' },
  { code: 'HT', currency: 'htg', name: 'Haiti' },
  { code: 'HN', currency: 'hnl', name: 'Honduras' },
  { code: 'HK', currency: 'hkd', name: 'Hong Kong' },
  { code: 'HU', currency: 'huf', name: 'Hungary' },
  { code: 'IS', currency: 'isk', name: 'Iceland' },
  { code: 'IN', currency: 'inr', name: 'India' },
  { code: 'ID', currency: 'idr', name: 'Indonesia' },
  { code: 'IE', currency: 'eur', name: 'Ireland' },
  { code: 'IL', currency: 'ils', name: 'Israel' },
  { code: 'IT', currency: 'eur', name: 'Italy' },
  { code: 'JM', currency: 'jmd', name: 'Jamaica' },
  { code: 'JP', currency: 'jpy', name: 'Japan' },
  { code: 'KZ', currency: 'kzt', name: 'Kazakhstan' },
  { code: 'KE', currency: 'kes', name: 'Kenya' },
  { code: 'KG', currency: 'kgs', name: 'Kyrgyzstan' },
  { code: 'LA', currency: 'lak', name: "Lao People's Democratic Republic" },
  { code: 'LV', currency: 'eur', name: 'Latvia' },
  { code: 'LB', currency: 'lbp', name: 'Lebanon' },
  { code: 'LS', currency: 'lsl', name: 'Lesotho' },
  { code: 'LR', currency: 'lrd', name: 'Liberia' },
  { code: 'LT', currency: 'eur', name: 'Lithuania' },
  { code: 'LU', currency: 'eur', name: 'Luxembourg' },
  { code: 'MO', currency: 'mop', name: 'Macao' },
  { code: 'MK', currency: 'mkd', name: 'Macedonia' },
  { code: 'MG', currency: 'mga', name: 'Madagascar' },
  { code: 'MW', currency: 'mwk', name: 'Malawi' },
  { code: 'MY', currency: 'myr', name: 'Malaysia' },
  { code: 'MV', currency: 'mvr', name: 'Maldives' },
  { code: 'MT', currency: 'eur', name: 'Malta' },
  { code: 'MR', currency: 'mro', name: 'Mauritania' },
  { code: 'MU', currency: 'mur', name: 'Mauritius' },
  { code: 'MX', currency: 'mxn', name: 'Mexico' },
  { code: 'MD', currency: 'mdl', name: 'Moldova' },
  { code: 'MN', currency: 'mnt', name: 'Mongolia' },
  { code: 'MA', currency: 'mad', name: 'Morocco' },
  { code: 'MZ', currency: 'mzn', name: 'Mozambique' },
  { code: 'MM', currency: 'mmk', name: 'Myanmar' },
  { code: 'NA', currency: 'nad', name: 'Namibia' },
  { code: 'NP', currency: 'npr', name: 'Nepal' },
  { code: 'NI', currency: 'nio', name: 'Nicaragua' },
  { code: 'NG', currency: 'ngn', name: 'Nigeria' },
  { code: 'NO', currency: 'nok', name: 'Norway' },
  { code: 'PK', currency: 'pkr', name: 'Pakistan' },
  { code: 'PA', currency: 'pab', name: 'Panama' },
  { code: 'PG', currency: 'pgk', name: 'Papua New Guinea' },
  { code: 'PY', currency: 'pyg', name: 'Paraguay' },
  { code: 'PE', currency: 'pen', name: 'Peru' },
  { code: 'PH', currency: 'php', name: 'Philippines' },
  { code: 'PL', currency: 'pln', name: 'Poland' },
  { code: 'PT', currency: 'eur', name: 'Portugal' },
  { code: 'QA', currency: 'qar', name: 'Qatar' },
  { code: 'KR', currency: 'krw', name: 'Republic of Korea' },
  { code: 'CG', currency: 'xaf', name: 'Republic Of Congo' },
  { code: 'RO', currency: 'ron', name: 'Romania' },
  { code: 'RU', currency: 'rub', name: 'Russian Federation' },
  { code: 'RW', currency: 'rwf', name: 'Rwanda' },
  { code: 'LC', currency: 'xcd', name: 'Saint Lucia' },
  { code: 'WS', currency: 'wst', name: 'Samoa' },
  { code: 'ST', currency: 'std', name: 'São Tomé and Príncipe' },
  { code: 'SA', currency: 'sar', name: 'Saudi Arabia' },
  { code: 'SN', currency: 'xof', name: 'Senegal' },
  { code: 'RS', currency: 'rsd', name: 'Serbia' },
  { code: 'SC', currency: 'scr', name: 'Seychelles' },
  { code: 'SL', currency: 'sll', name: 'Sierra Leone' },
  { code: 'SG', currency: 'sgd', name: 'Singapore' },
  { code: 'SK', currency: 'eur', name: 'Slovakia' },
  { code: 'SI', currency: 'eur', name: 'Slovenia' },
  { code: 'SB', currency: 'sbd', name: 'Solomon Islands' },
  { code: 'SO', currency: 'sos', name: 'Somalia' },
  { code: 'ZA', currency: 'zar', name: 'South Africa' },
  { code: 'ES', currency: 'eur', name: 'Spain' },
  { code: 'LK', currency: 'lkr', name: 'Sri Lanka' },
  { code: 'SR', currency: 'srd', name: 'Suriname' },
  { code: 'SZ', currency: 'szl', name: 'Swaziland' },
  { code: 'SE', currency: 'sek', name: 'Sweden' },
  { code: 'CH', currency: 'chf', name: 'Switzerland' },
  { code: 'TW', currency: 'twd', name: 'Taiwan' },
  { code: 'TJ', currency: 'tjs', name: 'Tajikistan' },
  { code: 'TZ', currency: 'tzs', name: 'Tanzania' },
  { code: 'TH', currency: 'thb', name: 'Thailand' },
  { code: 'TO', currency: 'top', name: 'Tonga' },
  { code: 'TT', currency: 'ttd', name: 'Trinidad And Tobago' },
  { code: 'TR', currency: 'try', name: 'Turkey' },
  { code: 'UG', currency: 'ugx', name: 'Uganda' },
  { code: 'UA', currency: 'uah', name: 'Ukraine' },
  { code: 'AE', currency: 'aed', name: 'United Arab Emirates' },
  { code: 'UY', currency: 'uyu', name: 'Uruguay' },
  { code: 'UZ', currency: 'uzs', name: 'Uzbekistan' },
  { code: 'VN', currency: 'vnd', name: 'Vietnam' },
  { code: 'WF', currency: 'xpf', name: 'Wallis And Futuna' },
  { code: 'YE', currency: 'yer', name: 'Yemen' },
  { code: 'ZM', currency: 'zmw', name: 'Zambia' },
];

export const CURRENCIES = [
  'usd',
  'cad',
  'aud',
  'gbp',
  'eur',
  'nzd',
  'chf',
  'dkk',
  'nok',
  'sek',
  'bgn',
  'pln',
  'ron',
  'huf',
  'thb',
  'brl',
  'mxn',
];

export const sellerTypeSchema = z
  .union([
    z.literal('individual'),
    z.literal('dealer'),
    z.literal('privateer'),
    z.literal('small_business'),
  ])
  .default('individual');
export type SellerType = z.infer<typeof sellerTypeSchema>;

export const vehicleSchema = z.object({
  make: z.string(),
  model: z.string(),
  year: z.number(),
  type: z.union([
    z.literal('DIRT_BIKE'),
    z.literal('ATV'),
    z.literal('UTV'),
    z.literal('TRIALS'),
    z.literal('STREET'),
    z.literal('DUAL_SPORT'),
  ]),
});
export type Vehicle = z.infer<typeof vehicleSchema>;
