import { z } from 'zod';
import { countrySchema } from './shared';

export const addressSchema = z.object({
  address_line1: z.string().min(1),
  address_line2: z.string().nullable().optional(),
  address_residential_indicator: z
    .union([z.literal('yes'), z.literal('no'), z.literal('unknown')])
    .optional(),
  city_locality: z.string().default(''),
  country_code: countrySchema,
  is_default: z.boolean().default(false).optional(),
  is_shop_location: z.boolean().optional(),
  name: z.string(), // at least 2 words with at least 2 letters each and no special characters - usps req.
  phone: z
    .string()
    .regex(/^(.*\d){10,}.*$/, 'Phone must be ten digits.')
    .default(''),
  postal_code: z.string().optional(),
  state_province: z.string().nullable().optional(),
});

export interface AddressDocument extends z.output<typeof addressSchema> {}
export interface AddressInput extends z.input<typeof addressSchema> {}
