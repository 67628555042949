import { Item, logEvent as logFirebaseEvent } from '@firebase/analytics';
import {
  analyticsPromise,
  FirebaseCallable,
  functions,
  httpsCallable,
} from '@util/firebase';
import { ProductDocument } from '@models/product';
import { EventTypes } from './analytics.types';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
dayjs.extend(tz);

export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;
const isProd = process.env.NODE_ENV === 'production';

export const pageView = (url: string) => {
  if ((window as any).fbq) (window as any).fbq('track', 'PageView');
  url;
  // TODO: might not need this, check GA page views platform web
  // logEvent('screen_view', { firebase_screen: url, firebase_screen_class: url });
};

// all GA4 events not listed below
export async function logEvent(
  eventName: EventTypes,
  eventParams?: {
    coupon?: string | undefined;
    currency?: string | undefined;
    items?: Item[] | undefined;
    payment_type?: string | undefined;
    value?: number | undefined;
    [key: string]: any;
  },
  uid?: string
) {
  if (!isProd) return;
  try {
    // fb pixel events
    if (eventName === 'add_payment_info') addPaymentInfo(eventParams as any);
    if (eventName === 'add_to_wishlist') addToWishlist(eventParams!, uid!);
    if (eventName === 'purchase') logPurchase(eventParams!, uid!);
    if (eventName === 'created_offer') logOffer(eventParams!.items![0], uid!);
    // all other events
    const analytics = await analyticsPromise;
    if (analytics)
      logFirebaseEvent(analytics, eventName as string, eventParams);
  } catch (e) {
    logError(e);
  }
}

// Main analytics functions with extra config

export async function viewContent(
  item: ProductDocument,
  referrer: string,
  uid?: string
) {
  if (!isProd) return null;
  try {
    if ((window as any).fbq)
      (window as any).fbq(
        'track',
        'ViewContent',
        {
          content_ids: [item.id],
          content_name: item.title,
          content_category: item.category,
          content_type: referrer,
          contents: [{ id: item.id, quantity: 1 }],
          value: item.price,
          currency: 'USD',
        },
        { eventID: uid + ' ' + item.id }
      );
    await httpsCallable(
      functions,
      FirebaseCallable.viewedProduct
    )({
      item,
      total: item.price,
      from_web: true,
    });
    const analytics = await analyticsPromise;
    if (analytics) {
      logFirebaseEvent(analytics, 'view_item', {
        currency: 'usd',
        value: item.price,
        items: [getItem(item)],
      });
      logFirebaseEvent(analytics, 'category_product_view', {
        category: item.category,
        subcategory: item.category1,
        item_id: item.id,
        value: item.price,
        items: [getItem(item)],
      });
    }
  } catch (e) {
    logError(e);
  }
}

export async function logInitCheckout(
  items: ProductDocument[],
  total: number,
  uid: string
) {
  if (!isProd) return;
  try {
    if ((window as any).fbq)
      (window as any).fbq(
        'track',
        'InitiateCheckout',
        {
          content_ids: items.map((i) => i.id),
          contents: items.map((i) => {
            return { id: i.id, quantity: 1 };
          }),
          num_items: items.length,
          value: total,
          currency: 'USD',
        },
        { eventID: uid + ' ' + items[items.length - 1].id }
      );
    await httpsCallable(
      functions,
      FirebaseCallable.initiateCheckout
    )({
      items,
      total,
      isComplete: false,
      from_web: true,
    });
    const analytics = await analyticsPromise;
    if (analytics)
      logFirebaseEvent(analytics, 'begin_checkout', {
        currency: 'usd',
        value: total,
        items: getItems(items),
      });
  } catch (e) {
    logError(e);
  }
}

export async function logAddToCart(
  item: ProductDocument,
  total: number,
  uid: string
) {
  if (!isProd) return;
  try {
    if ((window as any).fbq)
      (window as any).fbq(
        'track',
        'AddToCart',
        {
          content_ids: [item.id],
          content_name: item.title,
          content_type: item.category,
          contents: [{ id: item.id, quantity: 1 }],
          value: total,
          currency: 'USD',
        },
        { eventID: uid + '_' + item.id }
      );
    await httpsCallable<
      { item: ProductDocument; total: number; from_web: boolean },
      void
    >(
      functions,
      FirebaseCallable.addToCart
    )({ item, total, from_web: true });
    const analytics = await analyticsPromise;
    if (analytics)
      logFirebaseEvent(analytics, 'add_to_cart', {
        currency: 'usd',
        value: item.price,
        items: [getItem(item)],
      });
  } catch (e) {
    logError(e);
  }
}

export async function completeRegistration(
  uid: string,
  method: string,
  email: string
) {
  try {
    await httpsCallable<
      { email: string; from_web: boolean; timezone: string },
      void
    >(
      functions,
      FirebaseCallable.completeRegistration
    )({ email, from_web: true, timezone: dayjs.tz.guess() });
    if ((window as any).fbq)
      (window as any).fbq(
        'track',
        'CompleteRegistration',
        {
          status: true,
          content_name: method,
        },
        { eventID: uid }
      );
    const analytics = await analyticsPromise;
    if (analytics) logFirebaseEvent(analytics, 'sign_up', { method });
  } catch (e) {
    logError(e);
  }
}

// helper fb pixel functions

function logPurchase(
  eventParams: { transaction_id?: string; value?: number },
  uid: string
) {
  if (!isProd) return;
  try {
    if ((window as any).fbq)
      (window as any).fbq(
        'track',
        'Purchase',
        { value: eventParams.value, currency: 'USD' },
        { eventID: uid + ' ' + eventParams.transaction_id! }
      );
  } catch (e) {
    logError(e);
  }
}

function addPaymentInfo(eventParams: { id: string; is_payout: boolean }) {
  try {
    if ((window as any).fbq)
      (window as any).fbq(
        'track',
        'AddPaymentInfo',
        {
          content_ids: [eventParams.id],
          content_category: eventParams.is_payout
            ? 'For Payout'
            : 'For Purchase',
        },
        { eventID: eventParams.id }
      );
  } catch (e) {
    logError(e);
  }
}

function addToWishlist(
  eventParams: {
    currency?: string;
    items?: Item[];
    value?: number;
  },
  uid: string
) {
  try {
    if ((window as any).fbq)
      (window as any).fbq(
        'track',
        'AddToWishlist',
        {
          content_ids: [eventParams.items![0].item_id!],
          content_name: eventParams.items![0].item_name,
          value: eventParams.value,
          currency: eventParams.currency!,
        },
        { eventID: uid + ' ' + eventParams.items![0].item_id! }
      );
  } catch (e) {
    logError(e);
  }
}

function logOffer(item: Item, uid: string) {
  try {
    if ((window as any).fbq)
      (window as any).fbq(
        'track',
        'Lead',
        {
          content_category: item.item_category,
          content_name: item.item_name,
          value: item.price,
          currency: 'USD',
        },
        { eventID: uid + ' ' + item.id }
      );
  } catch (e) {
    logError(e);
  }
}

export const getItems = (items: ProductDocument[]) => {
  return items.map((i) => getItem(i));
};

export const getItem = (i: ProductDocument) => {
  return {
    item_id: i.id,
    item_name: i.title,
    item_brand: i.brand || 'Google',
    item_category: i.category ?? undefined,
    item_category1: i.category1 ?? undefined,
    item_category2: i.category2 ?? undefined,
    price: i.price,
    quantity: 1,
  };
};

const logError = (e: any) => console.error(e);
