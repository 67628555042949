import { CountryCode } from 'libphonenumber-js/types';
import { z } from 'zod';
import { addressSchema } from './address';
import {
  dateTimeSchema,
  genderSchema,
  notificationTypeSchema,
  sellerTypeSchema,
  uidSchema,
  userBikeSchema,
} from './shared';

export type UserActivity = {
  state: 'online' | 'offline';
  last_changed: number;
  app?: number;
  web?: number;
};

export const notificationSettings = {
  pow: {
    push: true,
    email: true,
    sms: true,
  },
  bow: {
    push: true,
    email: true,
    sms: true,
  },
  arrivals: {
    push: true,
    email: true,
    sms: true,
  },
  inbox: {
    push: true,
    email: true,
    sms: true,
  },
  offer: {
    push: true,
    email: true,
    sms: true,
  },
  order: {
    push: true,
    email: true,
    sms: true,
  },
  followed: {
    push: true,
    email: false,
    sms: true,
  },
  offer_seller: {
    push: true,
    email: true,
    sms: true,
  },
  order_seller: {
    push: true,
    email: true,
    sms: true,
  },
  marketing: {
    push: true,
    email: true,
    sms: true,
  },
};

const badgeCountsSchema = z.object({
  quick_replies: z.number(),
  as_described: z.number(),
  fast_shipper: z.number(),
});

export const roleSchema = z.object({
  admin: z.boolean().optional(),
  auctions: z.boolean().optional(),
  bike_shipper: z.boolean().optional(),
  blog: z.boolean().optional(),
  curated_lists: z.boolean().optional(),
  experiences: z.boolean().optional(),
  expert: z.boolean().optional(),
  integrations: z.boolean().optional(),
  marketing: z.boolean().optional(),
  reseller: z.boolean().optional(),
  seo: z.boolean().optional(),
  support: z.boolean().optional(),
});

const defaultMetrics = {
  first_purchase: 0,
  last_purchase: 0,
  ltv_purchased: 0,
  num_purchased: 0,
  num_refunded: 0,
  ltv_sold: 0,
  num_sold: 0,
  first_sold: 0,
  last_sold: 0,
  last_listed: 0,
  time_to_ship: 0,
};

export const metricSchema = z.object({
  first_purchase: z.number(), // timesteamp of the first item purchased
  last_purchase: z.number(), // timesteamp of the last item purchased
  ltv_purchased: z.number(), // lifetime sum of purchases in dollars
  num_purchased: z.number(),
  num_refunded: z.number(),
  ltv_sold: z.number(), // lifetime sum of sales in dollars
  num_sold: z.number(),
  first_sold: z.number(), // time stamp of the first item sold
  last_sold: z.number(), // time stamp of the last item sold
  last_listed: z.number(), // time stamp of the last item listed
  time_to_ship: z.number(),
  live_listings: z.number().optional(), // deprecated
  dropped_price: z.boolean().optional(), // deprecated
});

const sportsSchema = z.object({
  adv: z.boolean().optional(),
  atv: z.boolean().optional(), // so we want to show them all sports by default
  cycling: z.boolean().optional(),
  mtb: z.boolean().optional(), // also means they haven't selected their sports
  mx: z.boolean().optional(), // undefined means true for backwards compatibility
  snow: z.boolean().optional(),
  street: z.boolean().optional(),
  utv: z.boolean().optional(),
});
export type Sports = z.infer<typeof sportsSchema>;

export const notificationTypes = z.union([
  z.literal('pow'),
  z.literal('bow'),
  z.literal('arrivals'),
  z.literal('inbox'),
  z.literal('offer'),
  z.literal('order'),
  z.literal('followed'),
  z.literal('offer_seller'),
  z.literal('order_seller'),
  z.literal('marketing'),
]);

const letterSizeSchema = z.union([
  z.literal('XXS'),
  z.literal('XS'),
  z.literal('S'),
  z.literal('M'),
  z.literal('L'),
  z.literal('XL'),
  z.literal('XXL'),
  z.literal('XXXL'),
]);

export const userSizesSchema = z.object({
  boots: z.number().gte(1).lte(16).optional(),
  gloves: letterSizeSchema.optional(),
  helmet: letterSizeSchema.optional(),
  jersey: letterSizeSchema.optional(),
  pants: z.number().gte(6).lte(60).optional(),
});

export const usernameSchema = z
  .string({ required_error: 'Username is required' })
  .min(3, 'Must be 3 or more characters')
  .regex(/^[A-Za-z0-9\-_\'\ ]+$/, 'Special characters not allowed');

export const emailSchema = z
  .string({ required_error: 'Email is required' })
  .email('Must enter a valid email');

export const passwordSchema = z
  .string({ required_error: 'Password is required' })
  .min(8, 'Must be 8 or more characters')
  .regex(/[A-Z]/, 'Upper & lowercase required')
  .regex(/[a-z]/, 'Upper & lowercase required')
  .regex(/[0-9]/, 'At least one number required');

export const userSchema = z.object({
  account_activated: z.boolean().default(false),
  account_id: z.string().optional(), // seller account id from Stripe
  addresses: z.array(addressSchema).default([]),
  app_data: z
    .object({
      advertiser_tracking_enabled: z.string(),
      application_tracking_enabled: z.string(),
      extinfo: z.array(z.union([z.string(), z.number()])),
    })
    .optional(),
  badge_counts: badgeCountsSchema.default({
    as_described: 0,
    fast_shipper: 0,
    quick_replies: 0,
  }),
  bank_token: z.string().optional(), // to be used by app only (set on web and immediately deleted in app)
  banned: z.boolean().default(false),
  bikes: z.array(userBikeSchema).default([]),
  bio: z.string().optional(),
  brands: z.array(z.string()).default([]),
  business_type: z
    .union([z.literal('individual'), z.literal('company')])
    .optional(),
  country: z
    .object({
      code: z.string().transform((x) => x.toUpperCase() as CountryCode),
      currency: z.string(),
      name: z.string(),
    })
    .default({ code: 'US', currency: 'USD', name: 'United States' }),
  created: dateTimeSchema.default(Date.now()),
  customer_id: z.string().optional(),
  deleted: z.boolean().optional(),
  // email_verified: z.boolean().default(false),
  email: z.string().default(''), // .email()
  favorites: z.array(z.string()).default([]),
  fee: z.number().optional(), // some sellers have negotiated a lower fee
  first: z.string().optional(),
  flag_count: z.number().default(0),
  followers: z.number().default(0),
  following: z.array(z.string()).default([]),
  from_web: z.boolean().default(true), // undefined = created from backend
  gender: genderSchema.default('M'),
  hide_status: z.boolean().default(false),
  is_guest: z.boolean().optional(),
  id_status: z.number().optional(), // 0 or undefined = not verified, 1 = pending, 2 = verified, 3 = rejected
  idfa: z.string().optional(),
  is_verified: z.boolean().default(false),
  klaviyo_id: z.string().optional(),
  label_preference: z
    .union([
      z.literal('label'),
      z.literal('qr_code'),
      z.literal('label_and_qr_code'),
    ])
    .optional(),
  last: z.string().optional(),
  metrics: metricSchema.default(defaultMetrics),
  name: z.string().default(''),
  notes: z
    .array(
      z.object({
        created: dateTimeSchema,
        text: z.string(),
      })
    )
    .optional(),
  notifications: z
    .record(
      notificationTypeSchema,
      z.object({
        email: z.boolean().default(true),
        push: z.boolean().default(true),
        sms: z.boolean().default(true),
      })
    )
    .default(notificationSettings),
  on_vacation: z.boolean().default(false),
  opted_out: z.boolean().default(false),
  // phone_verified: z.boolean().default(false),
  phone: z.string().default(''),
  photo: z
    .string()
    .default(
      'https://ui-avatars.com/api/?background=random&length=2&name=U&size=256'
    ),
  platforms: z
    .array(z.union([z.literal('ios'), z.literal('android'), z.literal('web')]))
    .default([]),
  rating: z.number().default(0),
  reviews: z.number().default(0),
  roles: roleSchema.optional(),
  seller_type: sellerTypeSchema.optional(),
  sizes: userSizesSchema.default({}),
  sports: sportsSchema.optional().default({ mx: true }),
  store_photo: z.string().optional(),
  store_wide_sale: z.number().optional(),
  stripe_requirements: z.array(z.string()).optional(),
  tax_states: z
    .array(z.string().transform((x) => x.toUpperCase() as CountryCode))
    .optional(),
  token: z.string().optional(), // app fcm token
  top_seller: z.boolean().default(false),
  tracking: z
    .union([
      z.literal('1'),
      z.literal('2'),
      z.literal('3'),
      z.literal('unrequested'),
    ])
    .optional(),
  uid: uidSchema,
  unsub: z
    .object({
      event: z.string().default(''),
      email_source: z.string().default(''),
    })
    .optional(),
  username_changed: z.number().optional(),
  username_slug: z.string().min(3).max(35),
  username: usernameSchema,
  verification_id: z.string().optional(), // from Stripe Identity
  version: z.string().optional(),
});

// schema for recommendation user profiles
export const interactionRecordSchema = z.object({
  pid: z.string(),
  p_embedding: z.array(z.number()),
  date: dateTimeSchema.optional(),
});

export const userProfileSchema = z.object({
  id: z.string(),
  uid: z.string(),
  activity_profile: z.custom<FirebaseFirestore.FieldValue>(
    (value) => value instanceof FirebaseFirestore.FieldValue
  ),
  interactions: z
    .object({
      views: z.array(interactionRecordSchema).optional(),
      favorites: z.array(interactionRecordSchema).optional(),
      add_to_cart: z.array(interactionRecordSchema).optional(),
      purchases: z.array(interactionRecordSchema).optional(),
      searches_clicked: z.array(interactionRecordSchema).optional(),
    })
    .optional(),
});

const sportsTypeSchema = z.union([
  z.literal('adv'),
  z.literal('atv'),
  z.literal('cycling'),
  z.literal('mtb'),
  z.literal('mx'),
  z.literal('snow'),
  z.literal('street'),
  z.literal('utv'),
]);

export const publicuserSchema = z.object({
  id: z.string(),
  uid: z.string(),
  title: z.string(), // needed for typesense search
  username: z.string(),
  username_slug: z.string(),
  photo: z.string(),
  is_verified: z.boolean(),
  top_seller: z.boolean(),
  rating: z.number(),
  reviews: z.number(),
  live_listings: z.number(),
  num_sold: z.number(),
  num_refunded: z.number(),
  followers: z.number(),
  seller_type: sellerTypeSchema.optional(),
  hide_status: z.boolean().optional(),
  badge_counts: badgeCountsSchema,
  country: z
    .object({
      code: z.string(),
      currency: z.string(),
      name: z.string(),
    })
    .optional(),
  created: dateTimeSchema,
  bio: z.string().optional(),
  sports: z
    .record(sportsTypeSchema, z.boolean())
    .default({
      adv: true,
      atv: true,
      cycling: true,
      mtb: true,
      mx: true,
      snow: true,
      street: true,
      utv: true,
    })
    .optional(),
  time_to_ship: z.number().optional(),
  store_wide_sale: z.number().optional(),
});

export const getUserArgsSchema = z.object({
  uid: uidSchema,
});
export const createuserSchema = userSchema;
export const updateUserRoleArgsSchema = z.object({
  uid: uidSchema,
  newRole: roleSchema,
});
export const updateUserDocArgsSchema = userSchema.partial();

export type CreateUserDocumentArgs = z.infer<typeof createuserSchema>;
export type GetUserArgs = z.infer<typeof getUserArgsSchema>;
export type UpdateUserRoleArgs = z.infer<typeof updateUserRoleArgsSchema>;
export type UpdateUserDocArgs = z.infer<typeof updateUserDocArgsSchema>;
export type Role = z.infer<typeof roleSchema>;
export type UserSizes = z.infer<typeof userSizesSchema>;
export interface UserInput extends z.input<typeof userSchema> {}
export interface UserDocument extends z.output<typeof userSchema> {}
export type UserProfile = z.infer<typeof userProfileSchema>;
export type PublicUserDocument = z.infer<typeof publicuserSchema>;

export interface InteractionDoc {
  cats_viewed?: {
    [cat: string]: { count: number; last_updated: number };
  };
  product_viewed?: string[];
  email_gear_viewed: string[];
  email_parts_viewed: string[];
  my_feed_viewed?: string[];
  my_feed_viewed_real?: string[];
}
