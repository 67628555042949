import { IMAGE_PATH, IMAGE_SIZE } from '.';
import { FAQTopic } from './maps/faq';
import { ProductDocument } from '@models/product';

export function thumbify(path: string) {
  return (
    path + `_${IMAGE_SIZE}?width=500&t=500&optimizer=image&aspect_ratio=1:1`
  );
}

export function getCdnImageUrls(remotePath: string) {
  const fPath = `${remotePath}_${IMAGE_SIZE}`;
  const tPath = `${thumbify(remotePath)}`;
  const full = `${IMAGE_PATH}${fPath}`;
  const thumb = `${IMAGE_PATH}${tPath}`;
  return { full, thumb };
}

export function getStoragePathFromCdnUrl({
  thumb,
  full,
}: {
  thumb: string;
  full: string;
}) {
  if (thumb.startsWith(IMAGE_PATH)) thumb = thumb.replace(IMAGE_PATH, '');
  if (full.startsWith(IMAGE_PATH)) full = full.replace(IMAGE_PATH, '');
  return { thumb, full };
}

export function encodeQueryData(
  data: Record<string, string | string[] | number | undefined>
) {
  const ret = [];
  for (let d in data) {
    const value = data[d];
    if (Array.isArray(value)) {
      for (let v of value) {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(v));
      }
    } else {
      if (value)
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(value));
    }
  }
  return ret.join('&');
}

export function getQuestionSlug(q: string) {
  return q
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/&/g, 'and')
    .replace(/\?/g, '');
}

export function getTopicSlug(topic: FAQTopic) {
  return topic.toLowerCase().replace(/ /g, '-').replace(/&/g, 'and');
}

export function getFaqUrl(topic?: FAQTopic, question?: string) {
  if (!topic) return '/faq';
  if (!question) return `/faq/${getTopicSlug(topic)}`;
  return `/faq/${getTopicSlug(topic)}/${getQuestionSlug(question)}`;
}

export const slugify = (text?: string, id?: string): string => {
  if (!text) return '';
  const slug = text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .slice(0, 60);
  return id ? `${slug}-${id.slice(0, 5)}` : slug;
};

export const getProductSlug = (product: ProductDocument) =>
  `/${product.category === 'Dirt Bikes' ? 'dirt-bikes-for-sale' : 'product'}/${
    product.slug
  }`;
