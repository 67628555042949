import { z } from 'zod';
import { addressSchema } from '@models/address';
import { sizeSchema } from 'models/shared';
import { couponSchema } from 'models/coupon';

export const cartItemSchema = z.object({
  product_id: z.string(),
  product_title: z.string(),
  product_image: z.string(),
  product_link: z.string(),
  product_cost: z.number(),
  seller_id: z.string().default(''), // TODO: CLEAN UP DATA--THIS SHOULDN'T BE OPTIONAL OR HAVE DEFAULTS
  qty: z.number().default(1),
  account_id: z.string().default(''), // TODO: CLEAN UP DATA--THIS SHOULDN'T BE OPTIONAL OR HAVE DEFAULTS
  shipping_cost: z.number().optional(),
  shipping_region: z.string().optional(),
  is_flat_rate: z.boolean().default(false),
  rate_id: z.string().optional(),
  service_type: z.string().optional(),
  size: sizeSchema.optional(),
  color: z.string().optional(),
  tax: z.number().optional(),
  attribution: z
    .object({
      data_source: z.string().optional(),
      page: z.string().optional(),
      section: z.string().optional(),
    })
    .optional()
    .default({}),
});

export const cartSchema = z.object({
  affirm_fee: z.number().default(0).optional(),
  ra_fee: z.number().default(0).optional(),
  created: z.number().default(Date.now()),
  last_updated: z.number().default(Date.now()),
  product_ids: z.array(z.string()).default([]),
  shipping: z.number().default(0),
  tax: z.number().default(0),
  subtotal: z.number().default(0),
  total: z.number().default(0),
  uid: z.string(),
  items: z.array(cartItemSchema).default([]),
  shipping_address: addressSchema.optional(),
  donation_amount: z.number().min(0).optional(),
  coupons: z.array(couponSchema).optional(),
  payment_type: z
    .literal('card')
    .or(z.literal('affirm'))
    .or(z.literal('balance'))
    .default('card'),
  payment_method_id: z
    .union([z.literal('affirm'), z.literal('balance'), z.string()])
    .optional(),
});

export type Cart = z.infer<typeof cartSchema>;
export type CartItem = z.infer<typeof cartItemSchema>;
