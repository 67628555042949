import { z } from 'zod';

export const interactionTypeSchema = z.union([
  z.literal('view'),
  z.literal('favorite'),
  z.literal('unfavorite'),
  z.literal('add_to_cart'),
  z.literal('purchase'),
  z.literal('search_clicked'),
]);

export const INTERACTION_WEIGHTS = {
  view: 1,
  search_clicked: 1.5,
  favorite: 2,
  add_to_cart: 3,
  purchase: 4,
} as const;

export type InteractionType = z.infer<typeof interactionTypeSchema>;
