import { z } from 'zod';
import { rideTypes } from './shared';

export const categorySchema = z.object({
  app_desc: z.string().trim().default(''),
  breadcrumbs: z.array(
    z.object({
      id: z.string().trim().min(2),
      name: z.string().trim().min(2),
      slug: z.string().trim().min(2),
    })
  ),
  cat_level: z.number(),
  color: z.string().trim().startsWith('#').length(7).default('#000000'),
  description: z.string().trim().min(1),
  full_slug: z.string().trim().min(1),
  header_image: z.string().trim().default(''), // banner image at the top of cat pages
  id: z.string().min(1),
  image: z.string().trim().default(''), // required if master product, otherwise its og:url image and at bottom of cat pages
  index: z.number().optional(),
  is_master_product: z.boolean().default(false),
  is_seo_only: z.boolean().default(false),
  is_universal_disabled: z.boolean().default(false),
  meta_desc: z.string().trim().min(1),
  meta_title: z.string().trim().min(1),
  name: z.string().trim().min(1),
  package: z
    .object({
      height: z.number().gt(0),
      length: z.number().gt(0),
      weight: z.number().gt(0),
      width: z.number().gt(0),
    })
    .optional(),
  product_cats: z
    .object({
      ride_type: rideTypes.nullable().default(null),
      category: z.string().trim().nullable().default(null),
      category1: z.string().trim().nullable().default(null),
      category2: z.string().trim().nullable().default(null),
      category3: z.string().trim().nullable().default(null),
      category4: z.string().trim().nullable().default(null),
    })
    .default({
      ride_type: null,
      category: null,
      category1: null,
      category2: null,
      category3: null,
      category4: null,
    }),
  ride_types: z.array(rideTypes).default([]),
  second_description: z.string().trim().default(''),
});

export interface CategoryDocument extends z.output<typeof categorySchema> {}

// type CatItem = { doc: CategoryDocument; map: Map<string, CatItem> };

export type CatMap = Map<
  string,
  {
    doc: CategoryDocument; //cat0
    map: Map<
      string,
      {
        doc: CategoryDocument; //cat1
        map: Map<
          string,
          {
            doc: CategoryDocument; //cat2
            arr: CategoryDocument[]; //cat3s
          }
        >;
      }
    >;
  }
>;
