import { countries } from 'countries-list';
import { z } from 'zod';

export const STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
] as const;

export type State = (typeof STATES)[number];

export const CANADA_PROVINCES = [
  'AB',
  'BC',
  'MB',
  'NB',
  'NL',
  'NT',
  'NS',
  'NU',
  'ON',
  'PE',
  'QC',
  'SK',
  'YT',
] as const;

export type CanadaProvince = (typeof CANADA_PROVINCES)[number];

export const AUSTRALIA_STATES = [
  'ACT',
  'NSW',
  'NT',
  'QLD',
  'SA',
  'TAS',
  'VIC',
  'WA',
] as const;

export type AustraliaState = (typeof AUSTRALIA_STATES)[number];

export const getStates = (country: string) => {
  switch (country) {
    case 'United States':
      return STATES;
    case 'Canada':
      return CANADA_PROVINCES;
    case 'Australia':
      return AUSTRALIA_STATES;
    default:
      return [];
  }
};

export const getCountries = () => {
  return Object.entries(countries)
    .map(([code, country]) => ({
      label: country.name,
      value: code,
      id: code,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const southAmCodes = [
  'AR',
  'BO',
  'BR',
  'CL',
  'CO',
  'EC',
  'FK',
  'GF',
  'GY',
  'PY',
  'PE',
  'SR',
  'UY',
  'VE',
];
export const europeCodes = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
];

export const minimalGoogleMapsLocationResponseSchema = z.object({
  label: z.string(),
  value: z.object({
    description: z.string(),
    place_id: z.string(),
  }),
});
