import { httpsCallable } from 'firebase/functions';
import { InteractionType } from './recommendations.types';
import { FirebaseCallable, functions } from '@util/firebase';
import { logError } from '@util/logError';
import { z } from 'zod';
import { genderSchema, sellerTypeSchema } from 'models/shared';
import { conditionTypes } from 'models/product';

export async function trackUserInteraction({
  uid,
  pid,
  interaction,
}: {
  uid: string;
  pid: string;
  interaction: InteractionType;
}) {
  await httpsCallable<
    {
      uid: string;
      pid: string;
      interaction: InteractionType;
    },
    void
  >(
    functions,
    FirebaseCallable.trackUserInteraction
  )({
    uid,
    pid,
    interaction,
  });
}

const sortSchema = z.union([
  z.literal('Default'),
  z.literal('Newest'),
  z.literal('Low Price'),
  z.literal('High Price'),
]);
export const BQFilter = z.object({
  brands: z.array(z.string()).default([]),
  categories: z.array(z.string().nullable()).default([]),
  condition: conditionTypes.nullable().default(null),
  gender: genderSchema.nullable().default(null),
  inserted_time: z.number().nullable().default(null),
  makes: z.array(z.string()).default([]),
  models: z.array(z.string()).default([]),
  on_sale: z.boolean().default(false),
  product_clicked: z.string().nullable().default(null),
  result_count: z.number().default(0),
  seller_type: sellerTypeSchema.nullable().default(null),
  size: z.string().nullable().default(null),
  sort: sortSchema.default('Default'),
  term: z.string().default(''),
  uid: z.string().nullable().default(null),
  years: z.array(z.number()).default([]),
  referrer: z.string().nullable().default(null),
  page: z.number().default(1),
});
export interface BQFilterInput extends z.input<typeof BQFilter> {}
export async function trackUserSearch(args: BQFilterInput) {
  try {
    const parsed = BQFilter.parse(args);
    if (
      process.env.NODE_ENV === 'production' &&
      !process.env.NEXT_PUBLIC_IS_STAGING
    ) {
      await httpsCallable<BQFilterInput, void>(
        functions,
        'v2_trackUserSearch'
      )(parsed);
    }
  } catch (e) {
    logError(e);
  }
}
